<template>
  <div class="container">
    <div class="list-header">
      <van-icon @click="$router.back(-1)" class="back-top-icon" name="arrow-left" />
      <div class="header-title">用户管理</div>
      <div class="header-left">&nbsp;</div>
    </div>
    <div class="h85"></div>
    <div class="u-list">
      <div v-for="item in list" :key="item.uuid" class="u-item">
        <div class="u-info">
          <div class="u-info-t">{{item.name}}<div class="u-info-age">{{item.age}}岁</div></div>          
          <div class="u-info-status" :class="{'un-active': item.status === 1}">
            <span :class="{'un-active-bg': item.status === 1}">&nbsp;</span>
            {{item.status === 1 ? '离线' : '在线' }}
          </div>
        </div>
        <div class="u-btn">
          <div @click="del(item)" class="u-btn-l">删除</div>
          <div @click="edit(item)" class="u-btn-r">编辑</div>
        </div>
      </div>
    </div>
    <!-- 新增按钮 -->
    <div @click="addUser()" class="add-btn">新建用户</div>
    <div class="h85"></div>
    <div class="h85"></div>
    <!-- 新增编辑弹窗 -->
    <div class="add-mask" v-show="showAdd">
      <div class="add-mask-body">
        <div class="add-mask-head">
          <div>{{title}}</div>
          <van-icon @click="showAdd = false" name="cross" color="#999999" size=".3rem"/>
        </div>
        <div class="add-cell">
          <div class="add-cell-tip">姓名</div>
          <van-field
            v-model.trim="form.name"
            label=""
            label-width="0"
            placeholder="请输入姓名"
            maxlength="10"
          />
        </div>
        <div class="add-cell">
          <div class="add-cell-tip">年龄</div>
          <van-field
            v-model="form.age"
            label=""
            label-width="0"
            placeholder="请输入年龄"
            maxlength="3"
          />
        </div>
        <div class="add-flag">
          {{ form.id ? `ID：${form.id}` : ' '}}
        </div>
        <div class="add-btns">
          <div @click="showAdd = false" class="add-btn del">取消</div>
          <div @click="submit" v-preventReClick class="add-btn sure">确认</div>
        </div>
      </div>
    </div>
    <!-- 删除二次确认 -->
    <div class="del-mask" v-show="showDel">
      <div class="del-mask-body">
        <div class="del-mask-head">
          <div>删除用户</div>
          <van-icon @click="showDel = false" name="cross" color="#999999" size=".3rem"/>
        </div>
        <div class="del-mask-tip">用户删除后，用户及数据不可恢复<br>是否删除此用户？</div>
        <div class="del-btns">
          <div @click="doDel" v-preventReClick class="del-btn del">确认</div>
          <div @click="showDel = false" class="del-btn sure">再想想</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, RadioGroup, Radio, Field, Popup, Picker } from 'vant';
import { range, forEach, isArray } from 'lodash';
import moment from 'moment';
// console.log(tiZhongColumns, '----')
import { getUserList, syncSubUser, creteUser, updateUser, delUser } from '@/api/user.js'

export default {
  components: {[Icon.name]: Icon, [RadioGroup.name]: RadioGroup, [Radio.name]: Radio, [Field.name]: Field, [Popup.name]: Popup, [Picker.name]: Picker},
  data() {
    return {
      showDel: false,
      showAdd: false,
      title: '',
      form: {
        name: '',
        age: '',
        uuid: ''
      },
      list:[]
    }
  },
  created() {
    // const memberId = sessionStorage.getItem('userId')
    syncSubUser({}).then(res => {
      console.log(res, 'syncSubUser')
      this.getList()
    })    
  },
  methods: {
    getList() {
      getUserList({}).then(res2 => {
        console.log(res2)
        this.list = res2
      })
    },
    addUser() {
      this.title = '新增用户'      
      this.form = {
        name: '',
        age: '',
        uuid: ''
      }
      this.$nextTick(() => {
        this.showAdd = true
      })
    },
    doDel() {
      delUser(this.form.uuid).then(res=> {
        this.$toast('删除成功')
        this.getList();
        this.showDel = false
      })
    },
    del(item) {    
      this.form = {
        name: item.name,
        age: item.age,
        uuid: item.uuid
      }
      this.$nextTick(() => {
        this.showDel = true
      })
    },
    edit(item) {
      this.title = '编辑用户'
      this.form = {
        name: item.name,
        age: item.age,
        uuid: item.uuid
      }
      this.$nextTick(() => {
        this.showAdd = true
      })
    },
    submit() {
      if(!this.form.name) {
        this.$toast('请输入姓名')
        return
      }
      const agePattern = /^([1-9]|[1-9]\d|1[0-1]\d|120)$/;
      if(!agePattern.test(this.form.age)) {
        this.$toast('请输入正确的年龄')
        return
      }
      if(this.form.uuid) {
        updateUser({...this.form}).then(res => {
          this.$toast('更新成功')
          this.getList();
          this.showAdd = false;
        })
      } else {
        creteUser({...this.form, uuid: undefined}).then(res => {
          this.$toast('新增成功')
          this.getList();
          this.showAdd = false;
        }) 
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  background: #FFF;
  .add-btn {
    margin: 3rem auto;
    width: 4.17rem;
    height: .77rem;
    line-height: .77rem;
    background: #0074FE;
    border-radius: .38rem;
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: .27rem;
  }
  .add-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    .add-mask-body {
      margin: 3.4rem auto 0;
      padding: .38rem;
      position: relative;
      width: 5.97rem;
      // height: 3.86rem;
      background: #FFFFFF;
      border-radius: .12rem;
      .add-flag {
        // width: 2.85rem;
        padding: .19rem 0 .29rem;
        height: .38rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: .27rem;
        color: #000000;
        text-align: center;
      }
      .add-cell {
        padding-top: .29rem;
        /deep/ .van-cell {
          border: .02rem solid #eee;
          border-radius: .08rem;
          height: .62rem;
          line-height: .62rem;
          padding: 0;
          padding-left: .2rem;
          font-weight: 400;
          font-size: .27rem;
          color: #999999;
        }
        .add-cell-tip{
          font-weight: 400;
          font-size: .27rem;
          color: #000000;
          padding-bottom: .19rem;
        }
      }
      .add-mask-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        font-size: .31rem;
        color: #000000;
      }
      .add-btns {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: .77rem;
        .add-btn {
          width: 1.79rem;
          height: .77rem;
          border-radius: .12rem;
          font-weight: 500;
          font-size: .27rem;
          text-align: center;
          line-height: .77rem;
        }
        .sure {
          color: #FFF;
          background: #0074FE;
        }
        .del {
          background: #EEE;
          color: #999;
        }
      }
    }
  }
  .del-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    .del-mask-body {
      margin: 3.4rem auto 0;
      padding: .38rem;
      position: relative;
      width: 5.97rem;
      height: 3.86rem;
      background: #FFFFFF;
      border-radius: .12rem;
      .del-mask-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        font-size: .31rem;
        color: #000000;
      }
      .del-mask-tip {
        padding: .5rem 0 .8rem;
        // width: 4.62rem;
        text-align: center;
        height: 1.15rem;
        font-weight: 400;
        font-size: .31rem;
        color: #000000;
        line-height: .58rem;
      }
      .del-btns {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: .77rem;
        .del-btn {
          width: 1.79rem;
          height: .77rem;
          border-radius: .12rem;
          font-weight: 500;
          font-size: .27rem;
          text-align: center;
          line-height: .77rem;
        }
        .sure {
          color: #FFF;
          background: #0074FE;
        }
        .del {
          background: #EEE;
          color: #999;
        }
      }
    }
  }
  .u-list {
    padding: 0.3rem 0 .4rem 0.3rem;
    display: flex;
    flex-wrap: wrap;
    gap: .2rem;
    .un-active {
      color: #999 !important
    }
    .un-active-bg {
      background: #999 !important;
    }
    .u-item {
      width: 3.37rem;
      height: 1.69rem;
      background: #F6F8F9;
      border-radius: .12rem;
      .u-btn {
        height: .65rem;
        background: #EEE;
        border-radius: 0 0 .12rem .12rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: url('../../accesst/user/btns.png') no-repeat;
        background-size: 3.37rem .65rem;
        .u-btn-l {
          width: 1.27rem;
          height: .65rem;
          line-height: .65rem;
          text-align: center;
        }
        .u-btn-r {
          line-height: .65rem;
          color: #FFF;
          width: 2.17rem;
          height: .65rem;
          text-align: center;
        }
      }
      .u-info {
        padding: .29rem .15rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .u-info-t {
          font-weight: 500;
          font-size: .31rem;
          color: #000000;          
        }
        .u-info-age {
          margin-left: .15rem;
          display: inline-block;
          font-weight: 400;
          font-size: .27rem;
          color: #666666;
        }
        .u-info-status {
          font-weight: 600;
          font-size: .27rem;
          color: #00AD81;
          span {
            display: inline-block;
            width: .2rem;
            height: .2rem;
            border-radius: 50%;
            background: #00AD81;
            margin-right: .05rem;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .h85 {
    height: .85rem;
  }
  .list-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: .85rem;
    padding: 0 .46rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    .back-top-icon {
      width: .3rem;
      font-size: .32rem;
      color: #000;
      font-weight: bolder;
    }
    .header-title {
      font-size: .31rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
    .header-left {
      width: .3rem;
    }
  }
}
</style>